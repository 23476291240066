import axios from 'axios'

axios.defaults.headers = {
    'Cache-Control': 'no-cache',
    'Pragma': 'no-cache',
    'Expires': '0',
    'Accept': 'application/json',
    'Content-Type': 'application/json'
}

axios.interceptors.request.use(
    async (config) => {
        if (localStorage.getItem('jwt') !== null) {
            // let tokenExpires = Math.round(window.keycloak.tokenParsed.exp + window.keycloak.timeSkew - new Date().getTime() / 1000)
            config.headers.Authorization = `Bearer ${localStorage.getItem('jwt')}`
        } else {
            delete config.headers.Authorization
        }
        return config
    },
    (error) => {
        Promise.reject(error)
    }
)

// const API_BASE_URL = 'https://api.uk-aktuell.sunzity.de/api'
const API_BASE_URL = 'https://ukk-aktuell.uk-koeln.de/api'

/**
 * Sends a POST request to receive ..
 *
 * @returns {Promise<AxiosResponse<T>>} - Axios promise object
 */
export const signup = (email, password, passwordConfirm) =>
    axios.post(
        `${API_BASE_URL}/user/create`,
        // 'https://run.mocky.io/v3/8e51b6c3-137e-4a08-9c41-bc47a7ba910f', // returns 200
        // 'https://run.mocky.io/v3/3450ead8-1d93-4bdb-b13e-4efe1e0a821f', // returns 401
        {
            email: email,
            password: password,
            /* eslint-disable */
            password_confirmation: passwordConfirm
        }
    )

/**
 * Sends a POST request to receive ..
 *
 * @returns {Promise<AxiosResponse<T>>} - Axios promise object
 */
export const login = (email, password) =>
    axios.post(
        `${API_BASE_URL}/user/login`,
        // 'https://run.mocky.io/v3/8e51b6c3-137e-4a08-9c41-bc47a7ba910f', // returns 200
        // 'https://run.mocky.io/v3/3450ead8-1d93-4bdb-b13e-4efe1e0a821f', // returns 401
        {
            email: email,
            password: password
        }
    )

/**
 * Sends a GET request to receive ..
 *
 * @returns {Promise<AxiosResponse<T>>} - Axios promise object
 */
export const logout = () =>
    axios.get(
        `${API_BASE_URL}/user/logout`,
        // 'https://run.mocky.io/v3/8e51b6c3-137e-4a08-9c41-bc47a7ba910f', // returns 200
        // 'https://run.mocky.io/v3/3450ead8-1d93-4bdb-b13e-4efe1e0a821f', // returns 401
    )

export const deleteAccount = () =>
    axios.delete(
        `${API_BASE_URL}/user/me`
        // 'https://run.mocky.io/v3/8e51b6c3-137e-4a08-9c41-bc47a7ba910f', // returns 200
    )

/**
 * Sends a POST request to receive ..
 *
 * @returns {Promise<AxiosResponse<T>>} - Axios promise object
 */
export const recoverPassword = (email) =>
    axios.post(
        `${API_BASE_URL}/user/recover-password`,
        // 'https://run.mocky.io/v3/8e51b6c3-137e-4a08-9c41-bc47a7ba910f', // returns 200
        // 'https://run.mocky.io/v3/3450ead8-1d93-4bdb-b13e-4efe1e0a821f', // returns 401
        {
            email: email
        }
    )

/**
 * Sends a POST request to receive ..
 *
 * @returns {Promise<AxiosResponse<T>>} - Axios promise object
 */
export const getNewsFeed = (start) =>
    axios.post(
        `${API_BASE_URL}/news-feed/entries`,
        // 'https://run.mocky.io/v3/10b469dd-b8d8-4f2d-ae8f-93416b306bc4',
        // 'https://run.mocky.io/v3/14abeb5f-fa7a-40c2-a1ab-7cb689dee6e3',
        {
            page: start
        }
    )

/**
 * Sends a GET request to receive ..
 *
 * @returns {Promise<AxiosResponse<T>>} - Axios promise object
 */
export const getTopNews = () =>
    axios.get(
        `${API_BASE_URL}/news-feed/top-news`,
        // 'https://run.mocky.io/v3/d774bf9f-4373-4da4-b2fe-e3487f499a46'
        // 'https://run.mocky.io/v3/697388b8-e330-44dd-982b-ca8683d41f25' // empty response: {}
    )

/**
 * Sends a GET request to receive ..
 *
 * @returns {Promise<AxiosResponse<T>>} - Axios promise object
 */
export const getNewsEntry = (id) =>
    axios.get(
        `${API_BASE_URL}/news-feed/entries/${id}`,
        // 'https://run.mocky.io/v3/151d78d0-2369-4819-8193-c2fdca88d18a'
    )

/**
 * Sends a POST request to receive ..
 *
 * @returns {Promise<AxiosResponse<T>>} - Axios promise object
 */
export const getBlogFeed = (start) =>
    axios.post(
        `${API_BASE_URL}/blog/entries`,
        // 'https://run.mocky.io/v3/7af7a3ff-f806-4f5b-bf90-0a7b1d6db75f',
        // 'https://run.mocky.io/v3/8336bfd5-2817-4760-aba3-d5fae5839f49',
        {
            page: start
        }
    )

/**
 * Sends a POST request to receive ..
 *
 * @returns {Promise<AxiosResponse<T>>} - Axios promise object
 */
export const getRkiFeed = (start) =>
    axios.post(
        `${API_BASE_URL}/rki-feed/entries`,
        // 'https://run.mocky.io/v3/feafbbec-cfd7-46fb-85f7-4428e5b84997',
        {
            page: start,
            limit: 10
        }
    )

/**
 * Sends a GET request to receive ..
 *
 * @returns {Promise<AxiosResponse<T>>} - Axios promise object
 */
export const getFaqQuestions = () =>
    axios.get(
        `${API_BASE_URL}/faq/questions`,
        // 'https://run.mocky.io/v3/9176b2c4-7d49-4957-8954-caadf67ce244'
        // 'https://run.mocky.io/v3/697388b8-e330-44dd-982b-ca8683d41f25' // no response
    )

/**
 * Sends a GET request to receive ..
 *
 * @returns {Promise<AxiosResponse<T>>} - Axios promise object
 */
export const getFaqInfobox = () =>
    axios.get(
        `${API_BASE_URL}/faq/infobox`,
        // 'https://run.mocky.io/v3/44183ed4-c2c0-4fb0-9c66-6b99e688f06c'
    )

/**
 * Sends a GET request to receive search results
 *
 * @returns {Promise<AxiosResponse<T>>} - Axios promise object
 */
export const search = (q, start, limit) => {
    // return axios.get('https://run.mocky.io/v3/c981e8e6-ae4d-4af0-ae7c-3e872137acff')
    let url = `${API_BASE_URL}/search?q=${encodeURIComponent(q)}`
    url += '&page=' + (!start ? '1' : start)
    url += '&limit=' + (!limit ? '10' : limit)

    return axios.get(url)
    
}

/**
 * Sends a GET request to receive ..
 *
 * @returns {Promise<AxiosResponse<T>>} - Axios promise object
 */
export const getBlogTicker = () =>
axios.get(
    `${API_BASE_URL}/ticker/get`,
    // 'https://run.mocky.io/v3/64078a67-0ded-42c5-aa98-253f2475bea3'
)

/**
 * Sends a GET request to receive ..
 *
 * @returns {Promise<AxiosResponse<T>>} - Axios promise object
 */
export const getContentElements = () =>
axios.get(
    `${API_BASE_URL}/content-element`,
    // 'https://run.mocky.io/v3/87043f71-2ece-49dc-9d8e-2d491f4473d1'
)

/**
 * Sends a GET request to receive ..
 *
 * @returns {Promise<AxiosResponse<T>>} - Axios promise object
 */
export const getContentElementById = (id) =>
axios.get(
    `${API_BASE_URL}/content-element/${id}`,
    // 'https://run.mocky.io/v3/729bc5ab-30b9-4b7f-ab42-15a6f49de736'
)

/**
 * Sends a GET request to receive ..
 *
 * @returns {Promise<AxiosResponse<T>>} - Axios promise object
 */
 export const getEmployeurCampaign = () =>
 axios.get(
     `${API_BASE_URL}/button`,
    //  'https://run.mocky.io/v3/d18daf40-cb29-435d-811e-cc062bf9ee0b'
 )